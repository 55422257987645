<!--
  -- Created by Lichuangwei on 2022/10/14
  -- 【活动添加页面】
-->
<template>
  <moe-page title="活动添加">
    <moe-form ref="activityAddForm" :model="activityAdd" :rules="activityAddRules" :showBack="false" :showClose="true">
      <el-form-item label="活动名称" prop="name">
        <el-input v-model.trim="activityAdd.name" placeholder="请输入活动名称" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="活动位置" prop="alias">
        <moe-select type="activityAliasList" v-model="activityAdd.alias" placeholder="请选择活动位置" clearable @change="changeSelect('alias')"></moe-select>
      </el-form-item>

      <el-form-item label="展示风格" prop="style.mode">
        <el-select v-model="activityAdd.style.mode" placeholder="请选择展示风格" @change="changeSelect('style.mode')" clearable>
          <el-option :label="item.label" :value="item.value" v-for="(item, index) in activityAliasStyleListComputed" :key="index"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="列数" prop="style.column" v-if="['gallery', 'list', 'grid'].includes(activityAdd.style.mode)">
        <el-select v-model="activityAdd.style.column" placeholder="请选择列数" clearable>
          <el-option :label="item.label" :value="item.value" v-for="(item, index) in activityColumnListComputed" :key="index"></el-option>
        </el-select>
      </el-form-item>

      <template v-if="['secondaryPage', 'secondaryPage2', 'secondaryPage3'].includes(activityAdd.alias)">
        <el-form-item label="背景色" prop="style.backgroundColor">
          <el-input v-model="activityAdd.style.backgroundColor" placeholder="输入背景色" maxlength="50" clearable></el-input>
        </el-form-item>

        <el-form-item label="标题颜色" prop="style.color">
          <el-input v-model="activityAdd.style.color" placeholder="输入标题颜色" maxlength="50" clearable></el-input>
        </el-form-item>
      </template>

      <el-form-item label="最多展示商品数量" prop="goodsNum">
        <el-input :value="activityAdd.goodsNum" @input="(value) => activityAdd.goodsNum = value.replace(/^[^1-9]+|[^0-9]/g, '')" placeholder="请输入最多展示商品数量" maxlength="10" clearable></el-input>
      </el-form-item>

      <el-form-item label="排序" prop="sort">
        <el-input :value="activityAdd.sort" @input="(value) => activityAdd.sort = value.replace(/^\.+|[^\d]/g,'')" placeholder="请输入活动排序，数值越大排序越靠前" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="封面图片" prop="coverUrl">
        <moe-upload-file
          v-model="activityAdd.coverUrl"
          ossKey="SHOP_MEDIA"
          upload-text="上传封面图片"
          :default-file-list="coverUrlList"
          @change="() => $refs.activityAddForm.validateField('coverUrl')" />
      </el-form-item>

      <el-form-item label="活动介绍" prop="content">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model.trim="activityAdd.content" placeholder="输入活动介绍" maxlength="50" clearable/>
      </el-form-item>

      <el-form-item label="展示时间">
        <el-date-picker
          style="width: 100%;"
          placement="bottom-start"
          v-model="datetime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([activityAdd.startTime, activityAdd.endTime] = datetime || ['', ''])" />
          <div class="color-danger">（活动展示时间，时间结束活动自动关闭，如未选时间则一直存在，需手动关闭）</div>
      </el-form-item>

      <template slot="tool">
        <el-button type="primary" icon="el-icon-upload2" :loading="activityAddLoad" @click="activityAddInfoSubmit">{{ activityAddLoad ? '提交中' : '提交信息' }}</el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityAdd',
  computed: {
    IS_EDIT() {
      if (this.activityAdd.id) {
        return true
      } else {
        return false
      }
    },
    activityAliasStyleListComputed() {
      const { alias } = this.activityAdd;
      let activityAliasStyleList = this.$moe_lodash.cloneDeepData(this.$moe_data.activityAliasStyleList);
      if (['secondaryPage', 'secondaryPage2', 'secondaryPage3'].includes(alias)) {
        return activityAliasStyleList.slice(1, 4);
      } else {
        return activityAliasStyleList.slice(0, 1);
      }
    },
    activityColumnListComputed() {
      const { style: { mode } } = this.activityAdd;
      let activityColumnList = this.$moe_lodash.cloneDeepData(this.$moe_data.activityColumnList);
      if (!mode) {
        return [];
      } else if (['grid', 'gallery'].includes(mode)) {
        return activityColumnList.slice(2);
      } else if(['list'].includes(mode)) {
        return activityColumnList.slice(0, 2);
      } else {
        return activityColumnList.slice(0, 1);
      }
    }
  },
  data() {
    //智能设备信息
    const activityAdd = {
      id: '',
      name: '',
      alias: '',
      style: { mode: "", column: "",backgroundColor: "", color: "" },
      content: '',
      sort: '',
      coverUrl: '',
      goodsNum: '',
      startTime: '',
      endTime: '',
    }

    //智能设备数据校验
    const verify = this.$moe_verify.verifyForm
    const checkGoodsNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`请输入最多展示商品数量`));
      } else {
        if (this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          return callback(new Error(`最多展示商品数量必须大于0`));
        } else {
          callback();
        }
      }
    }
    const checkStyle = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`请输入展示风格`));
      } else {
        try {
          let isObject = JSON.parse(value);
          if (typeof (isObject) === 'object') {
            callback();
          } else {
            callback(new Error(`数据格式不正确`));
          }
        } catch {
          callback(new Error(`数据格式不正确`));
        }
      }
    }
    const activityAddRules = {
      name: verify.isEmpty('请输入活动名称'),
      alias: verify.isEmpty('请选择活动位置'),
      // goodsNum: [{ required: true, validator: checkGoodsNum, trigger: ['blur', 'change'] }],
      [`style.mode`]: verify.isEmpty('请选择展示风格'),
      [`style.column`]: verify.isEmpty('请选择列数'),
      // style: [{ required: true, validator: checkStyle, trigger: ['blur', 'change'] }],
    }

    return {
      activityAdd, //广告信息
      coverUrlList: [],
      datetime: [],
      activityAddRules, //广告数据校验
      activityAddLoad: false, //提交状态
    }
  },
  methods: {
    changeSelect(source) {
      switch (source) {
        case 'alias':
          this.activityAdd.style.mode = '';
          this.activityAdd.style.column = '';
          break;
        case 'style.mode':
          this.activityAdd.style.column = '';
          break;
      }
    },
    activityAddInfoSubmit() {
      this.$refs['activityAddForm'].validate(() => {
        this.activityAddLoad = true
        if (this.IS_EDIT) {
          this.req_activityAmend();
        } else {
          this.req_activityAdd();
        }
      })
    },
    req_activityAdd() {
      this.$moe_api.GOODS_API.activityAdd({
        ...this.activityAdd,
        sort: this.activityAdd.sort || 0,
        style: JSON.stringify(this.activityAdd.style),
      }).then((data) => {
        if (data.code == 200) {
          this.$moe_msg.success('添加成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.activityAddLoad = false
      })
    },
    req_activityAmend() {
      this.$moe_api.GOODS_API.activityAmend({
        ...this.activityAdd,
        sort: this.activityAdd.sort || 0,
        style: JSON.stringify(this.activityAdd.style),
      }).then((data) => {
        if (data.code == 200) {
          this.$moe_msg.success('修改成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.activityAddLoad = false;
      });
    },
    getActivityDetail() {
      if (this.$route.query.id) {
        this.$moe_api.GOODS_API.activityAmendDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code == 200) {
            let { id, name, alias, style, content, sort, coverUrl, goodsNum, startTime, endTime } = data.result;
            this.activityAdd = {
              id,
              name,
              alias,
              style: '',
              content,
              sort,
              coverUrl: coverUrl ? this.$moe_yunHelper.formatterImageUrl(coverUrl) : '',
              goodsNum: String(goodsNum),
              startTime,
              endTime,
            };

            if (style) {
              let { mode, column, backgroundColor, color } = JSON.parse(style);
              this.activityAdd.style = {
                mode: mode || '',
                column: column || '',
                backgroundColor: backgroundColor || '',
                color: color || '',
              }
            } else {
              this.activityAdd.style = { mode: "", column: "", backgroundColor: "", color: "" }
            }

            if (coverUrl) {
              this.coverUrlList = [
                {
                  url: coverUrl,
                  tempFilePath: this.$moe_yunHelper.getTempFilePath(coverUrl),
                }
              ];
            }

            if (startTime) {
              this.datetime = [startTime, endTime];
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
  },
  mounted() {
    this.getActivityDetail();
  }
}
</script>
